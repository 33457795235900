import { createApp } from 'vue'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

import Popper from "vue3-popper"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBan, faBroom, faCaretDown, faCaretUp, faEllipsisVertical, faFileExport, faPenToSquare, faShareAlt, faShield, faShieldHalved, faThumbsDown, faThumbsUp, faTrash, faUserAlt, faUserSlash } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faThumbsDown, faThumbsUp, faTrash, faUserSlash, faUserAlt, faCaretUp, faCaretDown, faBan, faPenToSquare, faBroom, faFileExport, faShield, faShieldHalved, faEllipsisVertical, faShareAlt)

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.js"
import './assets/scss/main.scss'
import { doHealthCheck } from './healthcheck'



doHealthCheck().then(async () =>{
  let app;
  if (location.pathname == "/share")
    app = await import("./EmbedApp.vue").then(({default: app}) => 
      createApp(app, Object.fromEntries(new URLSearchParams(location.search).entries()))
    )
  else
    app = await Promise.all([import("./App.vue"),import("./router")])
      .then(([{default:app},{default:router}]) => 
        createApp(app).use(router)
      )


  app.component('font-awesome-icon', FontAwesomeIcon)
    .component("Popper", Popper)
    .use(VueMonacoEditorPlugin)
    .mount('#app')


})

